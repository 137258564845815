import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { SSO_CONFIGURATIONS, MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name      : "SecurityConfigurations",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    isConfiguringDefaultSso             : false,
    hasToDisplayUserValidationAttributes: false,
    identifierCopied                    : false,
    replyUrlCopied                      : false,
    showEnableDefaultSsoDialog          : false,
    savingDefaultSsoConfiguration       : false,
    savingUserValidationAttributes      : false,
    removingUserValidationAttributes    : false,
    ssoValidationKeyCharacterLimit      : MAX_CHARACTER_LIMIT.SSO_VALIDATION_KEY,
    ssoValidationValueCharacterLimit    : MAX_CHARACTER_LIMIT.SSO_VALIDATION_VALUE,
    localDefaultSsoConfiguration        : {
      givenName         : undefined,
      email             : undefined,
      metadataUrl       : undefined,
      ssoValidationKey  : undefined,
      ssoValidationValue: undefined
    },
    timerForCopiedText: null
  }),
  props: {
    pIsDefaultSsoConfigured         : Boolean,
    pIsDefaultSsoEnabled            : Boolean,
    pClientUserPoolId               : String,
    pClientConfigurationId          : String,
    pDefaultSsoConfiguration        : Object,
    pIsSavingDefaultSsoConfiguration: Boolean,
    pIsDefaultSsoConfigurationSaved : Boolean,
    pIsEnablingDefaultSsoLogin      : Boolean,
    pIsDefaultSsoLoginEnabled       : Boolean,
    pDefaultSsoConfigurationError   : Object,
    pRegion                         : String
  },
  computed: {
    helpCenterURL() {
      return process.env.VUE_APP_SSO_HELP_URL
    },
    identifier() {
      return `${SSO_CONFIGURATIONS.URN_AMAZON_COGNITO_SP}${this.pClientUserPoolId}`
    },
    replyUrl() {
      return `https://${this.pClientConfigurationId}.${SSO_CONFIGURATIONS.AUTH}.${this.pRegion}.${SSO_CONFIGURATIONS.AMAZONCOGNITO_SAML_2_IDPRESPONSE}`
    },
    isDefaultSsoConfiguredOrEnabled() {
      return this.pIsDefaultSsoConfigured || this.pIsDefaultSsoEnabled
    },
    firstTimeConfiguringDefaultSso() {
      return !this.isDefaultSsoConfiguredOrEnabled && this.isConfiguringDefaultSso
    },
    updatingDefaultSsoConfiguration() {
      return this.isDefaultSsoConfiguredOrEnabled && this.isConfiguringDefaultSso
    },
    hasErrorWhileSavingDefaultSsoConfiguration() {
      return !!this.pDefaultSsoConfigurationError
    },
    isExceededSsoValidationKeyCharacterLimit() {
      return this.localDefaultSsoConfiguration.ssoValidationKey?.length > this.ssoValidationKeyCharacterLimit
    },
    isExceededSsoValidationValueCharacterLimit() {
      return this.localDefaultSsoConfiguration.ssoValidationValue?.length > this.ssoValidationValueCharacterLimit
    },
    hasUserValidationAttributesChanged() {
      return this.localDefaultSsoConfiguration.ssoValidationKey !== this.pDefaultSsoConfiguration.ssoValidationKey ||
        this.localDefaultSsoConfiguration.ssoValidationValue !== this.pDefaultSsoConfiguration.ssoValidationValue
    },
    isUserValidationAttributesFilled() {
      return this.localDefaultSsoConfiguration.ssoValidationValue?.length &&
        this.localDefaultSsoConfiguration.ssoValidationKey?.length
    },
    loaderForUserValidationAttributes() {
      return this.pIsSavingDefaultSsoConfiguration && this.savingUserValidationAttributes
    },
    loaderForSavingDefaultSsoConfiguration() {
      return this.pIsSavingDefaultSsoConfiguration && this.savingDefaultSsoConfiguration
    },
    loaderForRemovingUserValidationAttributes() {
      return this.pIsSavingDefaultSsoConfiguration && this.removingUserValidationAttributes
    },
    isSaveButtonDisabled() {
      return !(this.localDefaultSsoConfiguration.ssoValidationValue
      && this.localDefaultSsoConfiguration.ssoValidationKey)
      || !this.hasUserValidationAttributesChanged
      || this.isExceededSsoValidationKeyCharacterLimit
      || this.isExceededSsoValidationValueCharacterLimit
      || this.loaderForRemovingUserValidationAttributes
    },
    isUserValidationConfigured() {
      return this.isUserValidationAttributesFilled && !this.hasToDisplayUserValidationAttributes
    },
    editButtonClass() {
      return this.isUserValidationConfigured ? "col-md-2" : ""
    },
    userValidationHasValue() {
      return this.pDefaultSsoConfiguration.ssoValidationKey &&
        this.pDefaultSsoConfiguration.ssoValidationValue
    },
    userValidationWarning() {
      return this.hasToDisplayUserValidationAttributes &&
      this.userValidationHasValue && this.pIsDefaultSsoEnabled
    }
  },
  methods: {
    submitDefaultSsoConfiguration() {
      this.savingDefaultSsoConfiguration = true
      this.$emit("saveDefaultSsoConfiguration", this.localDefaultSsoConfiguration)
    },
    saveValidatingUser() {
      this.savingUserValidationAttributes = true
      this.$emit("saveUserValidationConfiguration", this.localDefaultSsoConfiguration)
    },
    cancelConfiguringDefaultSso() {
      if (this.pDefaultSsoConfiguration) {
        this.localDefaultSsoConfiguration = { ...this.pDefaultSsoConfiguration }
      } else {
        this.localDefaultSsoConfiguration = {
          givenName         : undefined,
          email             : undefined,
          metadataUrl       : undefined,
          ssoValidationKey  : undefined,
          ssoValidationValue: undefined
        }
      }
      this.isConfiguringDefaultSso = false
      this.identifierCopied        = false
      this.replyUrlCopied          = false
    },
    cancelValidatingUser() {
      this.localDefaultSsoConfiguration.ssoValidationKey = this.pDefaultSsoConfiguration.ssoValidationKey ?? undefined
      this.localDefaultSsoConfiguration.ssoValidationValue
        = this.pDefaultSsoConfiguration.ssoValidationValue ?? undefined
      this.hasToDisplayUserValidationAttributes          = false
    },
    removeUserValidationAttributes() {
      this.removingUserValidationAttributes = true
      this.$emit("saveUserValidationConfiguration", {
        ...this.localDefaultSsoConfiguration,
        ssoValidationKey  : "",
        ssoValidationValue: ""
      })
    },

    async copyIdentifier() {
      await navigator.clipboard.writeText(this.identifier)
      this.identifierCopied = true
      this.replyUrlCopied   = false
      if (this.timerForCopiedText) {
        clearTimeout(this.timerForCopiedText)
      }
      this.timerForCopiedText = setTimeout(() => this.identifierCopied = false, 2000)
    },

    async copyReplyUrl() {
      await navigator.clipboard.writeText(this.replyUrl)
      this.replyUrlCopied   = true
      this.identifierCopied = false
      if (this.timerForCopiedText) {
        clearTimeout(this.timerForCopiedText)
      }
      this.timerForCopiedText = setTimeout(() => this.replyUrlCopied = false, 2000)
    }
  },
  watch: {
    localDefaultSsoConfiguration: {
      immediate: false,
      deep     : true,
      handler  : function() {
        if (this.pDefaultSsoConfigurationError) {
          this.$emit("resetDefaultSsoConfigurationError")
        }
      }
    },
    pDefaultSsoConfiguration: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localDefaultSsoConfiguration = {
            ...newValue
          }
        }
      }
    },
    pIsDefaultSsoConfigurationSaved: {
      immediate: false,
      handler  : function(newValue) {
        if (this.savingUserValidationAttributes) {
          if (newValue) {
            this.savingUserValidationAttributes       = false
            this.hasToDisplayUserValidationAttributes = false
          }
        } else if (this.removingUserValidationAttributes) {
          if (newValue) {
            this.removingUserValidationAttributes     = false
            this.hasToDisplayUserValidationAttributes = false
          }
        } else {
          if (newValue) {
            this.savingDefaultSsoConfiguration = false
          }
          this.isConfiguringDefaultSso = !newValue
        }
      }
    },
    pIsEnablingDefaultSsoLogin: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.ENABLE_DEFAULT_SSO_LOGIN.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.ENABLE_DEFAULT_SSO_LOGIN.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsDefaultSsoLoginEnabled: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showEnableDefaultSsoDialog = false
        }
      }
    }
  }
}