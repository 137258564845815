import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name      : "TermsOfUse",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data() {
    return {
      localTermsOfUse                : null,
      termsOfUseCharacterLimit       : MAX_CHARACTER_LIMIT.TERMS_OF_USE,
      showDialogToRePublishTermsOfUse: false,
      showRemoveTermsOfUseDialog     : false
    }
  },
  props: {
    pTermsOfUse     : String,
    pIsUpdatingValue: Boolean,
    pIsValueUpdated : Boolean
  },
  computed: {
    hasToDisablePublishButton() {
      return !this.hasTermsOfUseChanged || !this.isTermsOfUseCharacterWithinLimit
    },
    hasTermsOfUseChanged() {
      return this.pTermsOfUse !== this.localTermsOfUse
    },
    isTermsOfUseCharacterWithinLimit() {
      return this.localTermsOfUse.length && this.localTermsOfUse.length <= this.termsOfUseCharacterLimit
    },
    hasToDisableDiscardButton() {
      return !this.hasTermsOfUseChanged || this.pIsUpdatingValue
    },
    isTermsOfUsePublished() {
      return this.pTermsOfUse.length
    },
    currentActionType() {
      return this.showRemoveTermsOfUseDialog ? "REMOVE_TERMS_OF_USE" : "RE_PUBLISH_TERMS_OF_USE"
    },
    isPublishButtonLoading() {
      return !this.showDialogToRePublishTermsOfUse &&
        !this.showRemoveTermsOfUseDialog && this.pIsUpdatingValue
    }
  },
  methods: {
    handleDiscardChanges() {
      this.localTermsOfUse = this.pTermsOfUse
    },
    handlePublishTermsOfUse() {
      if (this.pTermsOfUse && this.pTermsOfUse !== this.localTermsOfUse) {
        this.showDialogToRePublishTermsOfUse = true
      } else {
        this.$emit("publish", this.localTermsOfUse)
      }
    }
  },
  watch: {
    pTermsOfUse: {
      immediate: true,
      handler  : function(value) {
        this.localTermsOfUse = value
      }
    },
    pIsUpdatingValue: {
      handler: function(value) {
        const actionType                                             = this.currentActionType
        this.$DECISIONS[actionType].pActions[1].buttonProps.loading  = value
        this.$DECISIONS[actionType].pActions[0].buttonProps.disabled = value
      }
    },
    pIsValueUpdated: {
      immediate: true,
      handler  : function(value) {
        if (value) {
          this.showDialogToRePublishTermsOfUse = false,
          this.showRemoveTermsOfUseDialog = false
        }
      }
    }
  }
}