import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"

export default {
  name      : "GeneralConfigurations",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter
  },
  data: () => ({
    askOrganisationCode            : undefined,
    translationPreference          : undefined,
    isTranslationPreferenceUpdated : false,
    isUpdatingTranslationPreference: false,
    issueAcknowledgementOfReceipt  : undefined,
    allowAiSuggestions             : undefined
  }),
  props: {
    pTranslationPreferences         : Array,
    pIsLoadingTranslationPreferences: Boolean,
    pConfigurations                 : Array,
    pIsUpdatingValue                : Boolean,
    pIsValueUpdated                 : Boolean,
    pIsAiPrefillFieldsEnabled       : Boolean
  },
  computed: {
    isTranslationPreferenceChanged() {
      if (this.pConfigurations && this.pConfigurations.length) {
        return +this.pConfigurations.find(configuration => configuration.key === "TRANSLATION")?.value !== this.translationPreference
      }
    }
  },
  watch: {
    pConfigurations: {
      immediate: true,
      deep     : true,
      handler  : function(newValue) {
        if (newValue) {
          for (const configuration of newValue) {
            if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.ASK_ORGANISATION_CODE_FOR_WEB) {
              this.askOrganisationCode = configuration.value === "true"
            }
            if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.TRANSLATION) {
              this.translationPreference = +configuration.value
            }
            if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.SPEAKUP_ISSUE_ACKNOWLEDGEMENT) {
              this.issueAcknowledgementOfReceipt = configuration.value
            }
            if (configuration.key === this.$CONSTANTS.CONFIGURATIONS.AI_SUGGESTIONS) {
              this.allowAiSuggestions = configuration.value === "true"
            }
          }
        }
      }
    },
    pIsUpdatingValue: {
      immediate: true,
      handler  : function(newValue) {
        if (this.isTranslationPreferenceChanged) {
          this.isUpdatingTranslationPreference = newValue
        }
      }
    },
    pIsValueUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (this.isUpdatingTranslationPreference) {
            this.isUpdatingTranslationPreference = false
            this.isTranslationPreferenceUpdated  = newValue
          }
        } else {
          if (this.isTranslationPreferenceUpdated) {
            this.isTranslationPreferenceUpdated = newValue
          }
        }
      }
    }
  }
}
